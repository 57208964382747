.logo {
  height: 40px;
}

.mobile-menu-button {
  position: absolute;
  right: 0;
  top: 50%;
  user-select: none;
  cursor: pointer;
  transform: translate3d(0, -50%, 0);
}

.mobile-container {
  background: $color-bg-site;
  z-index: 201;
  position: relative;
}

.mobile-overlay {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  position: absolute;
  width: 100%;
  background: $color-bg-site;
  z-index: 100;
  top: 100%;
  left: 0;
}

.mobile-overlay-background {
  background: rgba(0, 0, 0, 0.5) !important;
}
