.btn-icon {
  display: inline-flex;
  align-items: center;
  > svg {
    font-size: 18px;
    margin-right: 18px;
  }
  &.btn-sm > svg {
    font-size: 14px;
    margin-right: 10px;
  }
}
