$font-weights: ("Regular": 400, "Bold": 500, "Black": 700);

@each $name, $weight in $font-weights {
  @font-face {
    font-family: 'Gotham Pro';
    src:url('../fonts/gotham/GothamPro-#{$name}.eot') format('embedded-opentype');
    src:url('../fonts/gotham/GothamPro-#{$name}.woff') format('woff'),
      url('../fonts/gotham/GothamPro-#{$name}.ttf') format('truetype');
    font-weight: $weight;
    font-style: normal;
  }
}

@font-face {
  font-family: 'Heading Pro Bold';
  src:url('../fonts/heading/Heading-Pro-Bold-trial.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Heading Pro Book';
  src:url('../fonts/heading/Heading-Pro-Book-trial.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
