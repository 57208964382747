.cookies-banner,
.cookies-banner-button {
  font-size: 12px;
}

.cookies-banner-close {
  font-size: 13px;
  display: inline-block;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}

.cookies-banner {
  background: $color-bg-secondary;
  position: relative;
  z-index: 201;
  padding-right: 35px;
  padding-left: 10px;
  line-height: 18px;
}
