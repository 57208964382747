.donate-banner {
  background: $color-on-dark;
  font-size: 16px;
  text-align: center;
  font-family: Lora, serif;
  box-shadow: $box-shadow;
  @include media-breakpoint-up(lg) {
    // height: 130px;
  }
}
.donate-banner-content {
  padding: 5px 0 5px;
  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}
.donate-banner-row {
  display: flex;
  align-items: center;
}
.donate-banner-col {
  &.message {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  > .gfm-embed {
    font-size: 0;
    line-height: 1;
  }
}
