.image-drop-zone {
  background: $color-bg-primary;
  transition: background 0.15s ease-in-out;
  max-width: 800px;
  margin: 0 auto;
  .files {
    color: $gray-600;
  }
  .image-icon {
    width: 30%;
    @include media-breakpoint-up(sm) {
      width: 30%;
    }
  }
  @include media-breakpoint-up(sm) {
    .dashed {
      border: 2px dashed $color-primary-2;
    }
    .no-dashed {
      border: 2px solid transparent;
    }
  }

  .btn-outline-primary:not(:focus):not(:disabled):not(:hover) {
    background: #ffffff !important;
  }
}
