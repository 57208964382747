.content {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    font-family: "Lora", serif;
    line-height: 1.6;
  }

  h1,
  .h1 {
    font-size: 30px;
    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
  }
  h2,
  .h2 {
    font-size: 22px;
    @include media-breakpoint-up(sm) {
      font-size: 26px;
    }
  }
  h3,
  .h3 {
    font-size: 19px;
    @include media-breakpoint-up(sm) {
      font-size: 22px;
    }
  }
  p {
    line-height: 1.6;
    font-size: 18px;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
    }
    &.serif {
      font-family: Lora, serif;
    }
  }
}

.light-subline {
  font-weight: 400;
  color: $color-light-subline;
}
