@import "./fonts";
@import "./bootstrap";
@import "./iconsmind";
@import "./socicon";
@import "./typography";
@import "./components";
@import "./buttons";
@import "./content";

footer {
  margin-top: 120px;
  img.flag {
    width: 28px;
  }
}

img {
  max-width: 100%;
}

.mw-0 {
  min-width: 0;
}

.o-0 {
  opacity: 0;
}

.t-size-0 {
  font-size: 16px;
}
.t-super {
  vertical-align: super;
}

.pointer {
  cursor: pointer;
}

.gradient {
  color: #fff;
  background-image: linear-gradient(-133deg, #f15b48 0%, #e9579f 100%);
}

.absolute-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

input[type="checkbox"],
label {
  cursor: pointer;
}

.fullscreen-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 200;
  background: $color-bg-site;
  overflow-y: auto;
}

.w-100px {
  width: 100px;
}

.border-light {
  border-color: $color-on-dark;
}

.link-lg {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
}

// html {
//   font-size: 14px;
// }
