.result-item {
  background: $color-bg-secondary;
  border: 2px solid transparent;
  .probability {
    color: $color-dark;
    font-size: 36px;
    font-weight: 600;
  }
  .probability-text {
    font-size: 16px;
  }
  .reporting {
    font-size: 16px;
  }
  .reporting-buttons > button {
    border-radius: 17px;
  }
  .external-link  {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    color: $color-primary-1;
    line-height: 18px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    padding-right: 15px;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    max-width: 100%;
    > span {
      text-decoration: underline;
      padding-right: 10px;
    }

  }
  .seeking-bar {
    position: relative;
    background: $color-primary-2;
    border-radius: 23px;
    height: 10px;
  }
  .seeking-position {
    position: absolute;
    top: 50%;
    display: block;
    background: $color-primary-1;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .seeking-time {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    > svg {
      color: $color-primary-1;
    }
  }
}
