.bg-gradient {
  background: $color-gradient;
  color: $color-on-dark;
}

.bar {
  margin-bottom: 60px;
  span {
    cursor: default;
    color: $color-on-dark;
    text-decoration: underline;
  }
}
