
$base-font-size: (14/16*100%); // change first value to pixel font size
$base-font-size-px: 14px; // the first value from above;
$base-font-size-mobile: (13/16*100%); // change first value to pixel font size
$base-font-size-mobile-px: 13px; // the first value from above;

$base-line-height: 1.85714285714286em;

$body-font-weight: 400;

html{ 
	font-size: $base-font-size;
}

@media all and (max-width: 768px){
	html{
		font-size: $base-font-size-mobile;
	}
}

body {
  line-height: $base-line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $body-font-weight;
}
