.image-box {
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 100%;
  background: $color-bg-primary;
  > img,
  > canvas {
    max-width: 80%;
    max-height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  > canvas {
    width: 80%;
    height: 80%;
  }
}

$features-list-margin-xs: 20px;
$features-list-margin-sm: 30px;
$features-list-margin-md: 15px;
$features-list-margin-lg: 20px;

.checkout-variant-1 > .payment-box {
  @include media-breakpoint-up(md) {
    padding-left: 0;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.payment-logo {
  width: 30px;
  height: 100%;
}

.payment-box {
  @include media-breakpoint-up(md) {
    padding-left: 30px;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 80px;
    padding-right: 0;
  }
  .price-teaser {
    font-size: 42px;
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      padding-top: $features-list-margin-xs;
    }
    @include media-breakpoint-up(sm) {
      padding-top: $features-list-margin-sm;
    }
    @include media-breakpoint-up(md) {
      padding-top: $features-list-margin-md;
    }
    @include media-breakpoint-up(lg) {
      font-size: 50px;
      padding-top: $features-list-margin-lg;
    }
    text-align: center;
    small.fs-25 {
      font-size: 25%;
    }
  }
}

.feature-check {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1.2) translate3d(0, 0, 0);
  @include media-breakpoint-up(md) {
    transform: initial;
    width: auto;
    position: absolute;
    right: auto;
    left: -10px;
    top: 50%;
    transform: translate3d(-100%, -50%, 0);
  }
  // @include media-breakpoint-up(xl) {
  //   position: relative;
  //   display: inline-block;
  //   margin-right: 10px;
  // }
}

.features-list {
  margin: 0;
  padding: 0;
  list-style: none;
  @include media-breakpoint-down(sm) {
    > li {
      font-size: 14px;
      padding-right: 40px;
      margin: $features-list-margin-xs 16px;
    }
  }
  @include media-breakpoint-up(sm) {
    > li {
      font-size: 17px;
      margin: $features-list-margin-sm 16px;
    }
  }

  @include media-breakpoint-up(md) {
    > li {
      font-size: 14px;
      text-align: left;
      left: 30px;
      margin: $features-list-margin-md 0;
    }
  }
  @include media-breakpoint-up(lg) {
    > li {
      font-size: 16px;
      margin: $features-list-margin-lg 0;
    }
  }
  @include media-breakpoint-up(xl) {
    > li {
      font-size: 15px;
      margin: $features-list-margin-lg 0;
    }
  }
}

.ssl {
  padding: 0;
  @include media-breakpoint-up(lg) {
    padding: 0 40px;
  }
  @include media-breakpoint-up(xl) {
    padding: 0 70px;
  }
}
.ssl-text {
  font-size: 10px;
  @include media-breakpoint-up(sm) {
    font-size: 13px;
  }
  line-height: 20px;
  font-weight: bold;
}

.price-box {
  min-width: 200px;
  margin: auto;
  display: inline-block;
  text-align: center;
  .price-row {
    padding: 30px 10px 24px;
    background: $color-bg-primary;
    color: $color-dark;
    font-size: 38px;
    font-weight: bold;
  }
  .meta-row {
    padding: 10px 0 8px;
    background: $color-primary-3;
    color: $color-on-dark;
    font-size: 19px;
    font-weight: bold;
  }
}
.price-popover {
  color: $color-primary-3;
  > span {
    cursor: help;
  }
}
