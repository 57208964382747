.webcam-container {
  display: flex;
  align-items: center;
  justify-content: center;
  > video {
    width: 100%;
  }
  position: relative;
  @include media-breakpoint-down(sm) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $color-bg-site;
  }
}

.camera-modal {
  width: 80vw;
  max-width: 1100px;
}

.webcam-initialize-spinner {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.canvas-face {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.webcam-fullscreen {
  width: 100%;
  height: 100%;
}

.webcam-buttons {
  position: relative;
  @include media-breakpoint-down(sm) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: $color-on-dark;
    background: rgba(0, 0, 0, 0.2);
  }
  color: $color-primary;
  background: $color-on-dark;
  font-weight: bold;
  font-size: 16px;
  .disabled {
    color: $gray-700;
    &:hover {
      text-decoration: none;
    }
  }
  > div {
    &:nth-child(2) {
      flex: 0;
    }
    flex: 1;
  }
  .cancel-button {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .take-picture-button {
    cursor: pointer;
    display: block;
  }
}
