.gear {
  text-align: center;
  position: relative;
  > svg {
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: 150px;
    }
  }
  .cog {
    transform-origin: center center;
    transform-box: fill-box;
  }
}
