// Required
@import "~bootstrap/scss/functions";
@import "./variables";
@import "~bootstrap/scss/mixins";

// Optional
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/alert";

.btn.btn-primary {
  padding: $btn-padding-y $btn-padding-x !important;
  border-color: transparent !important;
}

.min-h-100 {
  min-height: 100%;
}
