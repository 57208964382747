.hero-title {
  line-height: $headings-line-height;
  font-size: $h2-font-size;
  @include media-breakpoint-up(md) {
    font-size: $h1-font-size;
  }
  font-weight: bold;
}
.hero-subtitle {
  line-height: $headings-line-height;
  font-size: $h5-font-size;
  @include media-breakpoint-up(sm) {
    font-size: $h4-font-size;
  }
  font-weight: normal;
}
